var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Card",
        [
          _c("div", { attrs: { slot: "title" }, slot: "title" }, [
            _c("div", { staticClass: "edit-head" }, [
              _c(
                "a",
                { staticClass: "back-title", on: { click: _vm.close } },
                [
                  _c("Icon", { attrs: { type: "ios-arrow-back" } }),
                  _vm._v("返回 "),
                ],
                1
              ),
              _c("div", { staticClass: "head-name" }, [
                _vm._v(_vm._s(_vm.type == 0 ? "发送新消息" : "编辑消息")),
              ]),
              _c("span"),
              _c(
                "a",
                { staticClass: "window-close", on: { click: _vm.close } },
                [
                  _c("Icon", {
                    staticClass: "ivu-icon-ios-close",
                    attrs: { type: "ios-close", size: "31" },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "Row",
            [
              _c(
                "Form",
                {
                  ref: "form",
                  staticStyle: { position: "relative" },
                  attrs: {
                    model: _vm.form,
                    "label-width": 90,
                    rules: _vm.formValidate,
                  },
                },
                [
                  _c(
                    "FormItem",
                    { attrs: { label: "消息类型", prop: "type" } },
                    [
                      _c(
                        "Select",
                        {
                          staticStyle: { width: "250px" },
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type",
                          },
                        },
                        _vm._l(_vm.dictMessageType, function (item, i) {
                          return _c(
                            "Option",
                            { key: i, attrs: { value: item.value } },
                            [_vm._v(_vm._s(item.title))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("Input", {
                        staticStyle: { width: "600px" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    {
                      staticClass: "form-wangEditor",
                      attrs: { label: "内容", prop: "content" },
                    },
                    [
                      _c("editor", {
                        model: {
                          value: _vm.form.content,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "content", $$v)
                          },
                          expression: "form.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "FormItem",
                    {
                      attrs: { label: "新创建账号也推送", prop: "createSend" },
                    },
                    [
                      _c(
                        "i-switch",
                        {
                          attrs: { size: "large" },
                          model: {
                            value: _vm.form.createSend,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "createSend", $$v)
                            },
                            expression: "form.createSend",
                          },
                        },
                        [
                          _c(
                            "span",
                            { attrs: { slot: "open" }, slot: "open" },
                            [_vm._v("开启")]
                          ),
                          _c(
                            "span",
                            { attrs: { slot: "close" }, slot: "close" },
                            [_vm._v("关闭")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.type == 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "FormItem",
                            { attrs: { label: "发送范围" } },
                            [
                              _c(
                                "RadioGroup",
                                {
                                  model: {
                                    value: _vm.form.range,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "range", $$v)
                                    },
                                    expression: "form.range",
                                  },
                                },
                                [
                                  _c("Radio", { attrs: { label: 0 } }, [
                                    _vm._v("全体用户"),
                                  ]),
                                  _c("Radio", { attrs: { label: 1 } }, [
                                    _vm._v("指定用户成员"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _vm.form.range == 1
                                ? _c(
                                    "FormItem",
                                    { attrs: { label: "选择用户" } },
                                    [
                                      _c("user-choose", {
                                        ref: "user",
                                        attrs: { text: "选择发送用户" },
                                        on: {
                                          "on-change": _vm.handleSelectUser,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "FormItem",
                    { staticClass: "br" },
                    [
                      _c(
                        "Button",
                        {
                          staticStyle: { width: "100px" },
                          attrs: {
                            type: "primary",
                            loading: _vm.submitLoading,
                          },
                          on: { click: _vm.handelSubmit },
                        },
                        [_vm._v("提交")]
                      ),
                      _c("Button", { on: { click: _vm.close } }, [
                        _vm._v("取消"),
                      ]),
                    ],
                    1
                  ),
                  _vm.loading
                    ? _c("Spin", { attrs: { size: "large", fix: "" } })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }